import { Component, OnInit,ViewChild } from '@angular/core';
import { ModalController,NavController,LoadingController} from '@ionic/angular';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService, AnalyticsEventNames } from '../../propertiesFile/analytics.service';
import { EngLaService } from '../../propertiesFile/eng-la.service';
import { FrnLaService } from '../../propertiesFile/frn-la.service';
import {GcvrupCidiPopoverPage} from '../../addional/gcvrup-cidi-popover/gcvrup-cidi-popover.page';
import { PaymentModePage } from '../../addional/payment-mode/payment-mode.page';
import { Storage} from '@ionic/storage';
@Component({
  selector: 'app-modal-qconly-product',
  templateUrl: './modal-qconly-product.page.html',
  styleUrls: ['./modal-qconly-product.page.scss'],
})
export class ModalQCOnlyProductPage implements OnInit {
  textchk=false;
  emailchk=false;
  gcvrpuchk=false;
  pL;
  nM;
  rate;
  rateV;
  email;
  textmsg;
  px62;
  langOpt;
  dLan;
  dL1;
  ammnt;
  prevSelPro;
  qDC;
  cName;
  gen;
  age;
  riderage;
  agelabel;
  city;
  solve;
  medium;
  channelcode;
  mediumaddress;
  xd24;
  productType;
  paymentmode;
  sendemail;
  adrind = false;
  getGCVRPUCIDIVInd;
  riderArr;
  queryfortransfer;
  payeeType;
  disclaimermsg;
  t10ind;
  t20ind;
  msg="";
  source='modal-qconly-product'
  @ViewChild('emailtr', { static: false }) emailinput;
  @ViewChild('textmsgtr', { static: false }) textmsginput;
  constructor(public loadingController: LoadingController,/*private renderer: Renderer,*/private route: ActivatedRoute, private storage: Storage,private modalController: ModalController, private currencyPipe: CurrencyPipe, private navCtrl :NavController, public dX684: DataService, private http: HttpClient, private en: EngLaService,private analyticsService: AnalyticsService,private fr: FrnLaService) {
    this.route.queryParams.subscribe(params => {
      this.kroCheck();
      //const xx = this.navParams.get('alphaBateycaly');
      //const xx = this.navParams.get('dXT62');
      const xx=this.dX684.getQconlyProduct();
      //this.px62 = this.navParams.get('px');
      this.px62 = xx.px;
      //this.riderArr=this.navParams.get('riderArr');
      this.riderArr=xx.riderArr;
      //console.log(this.riderArr);
      this.nM = xx.nm;
      this.pL = xx.pl;
      this.t10ind=xx.t10ind;
      this.t20ind=xx.t20ind;
      //console.log(this.t10ind);
      this.adrind=xx.adrind;
      this.xd24 = this.dX684.getData();
      this.dX684.setData(this.xd24);
      this.cName=this.xd24.cName;
      this.prevSelPro = this.dX684.getQdOpD();
      this.gen = this.xd24.gender;
      this.city = this.xd24.city;
      this.queryfortransfer=xx.queryfortransfer;
      this.age = this.dX684.calcAge(this.xd24.dob);
      this.msg="";
      this.msg = this.dX684.getAdvPlusRiderRuleMsg(this.prevSelPro.name, this.dL1,this.t10ind,this.t20ind,this.age)
      if(this.prevSelPro) {
        this.qDC = true;
      }
      this.productType= this.dX684.getProductType(this.prevSelPro.name,this.xd24.prodTyp, this.dL1) ;
      this.riderage = this.dX684.getRiderAge(this.xd24.dob,this.prevSelPro.name, this.dLan).agevalue;
      this.agelabel = this.dX684.getRiderAge(this.xd24.dob,this.prevSelPro.name, this.dLan).agelabel;
      this.gen=this.dX684.getGender(this.dLan,this.gen);
      this.paymentmode = this.dX684.getPayeeType(this.xd24.freq,this.dL1)
      //console.log(this.paymentmode);
      this.sendemail=this.dX684.sendEmailIndicator(this.prevSelPro.name);
      this.solve = this.dX684.getSolve(this.xd24.solve,this.dL1);
      const solve = this.dX684.dTur_DX09Slv(this.xd24);
      this.payeeType = this.dX684.getPayeeType(this.xd24.freq,this.dL1)
      this.getGCVRPUCIDIVInd = this.dX684.getGCVRPUCIDIV(this.prevSelPro.name);
      this.ammnt = this.currencyPipe.transform(this.xd24.amount, 'USD', 'symbol', '1.0-0');
      if(this.prevSelPro.name ===this.dL1.quotecomparepage.p2)
        this.disclaimermsg=this.dX684.getQdOpD().subtype[0].stringArray[1].SArray[0]
      else
        this.disclaimermsg=this.dX684.getQdOpD().subtype[0].stringArray[0].SArray[0]
      if(!this.dX684.getSelectedRateClass()){
        this.rate = this.dX684.getDataToRateClassPopover()[this.xd24.rcValue];
      }
      else{
        this.rate = this.dX684.getSelectedRateClass();
      }
      this.rateV = this.dX684.fdXRcXV(this.rate);
    });
  }
  ngOnInit() { }
  closeModal() {
    this.navCtrl.navigateForward('/quote-details-only-product');
  }
  goLanding() {
    this.navCtrl.navigateRoot('/landing');
  }
  focus(field)
  {
    //console.log("inside focus:"+field+"email check"+this.emailchk);
    if(this.emailchk===true)
    {
      if(field==='email'){
        setTimeout(() => {
          this.emailinput.setFocus();
        }, 250);
      }
    }
    if(this.textchk===true)
    {
      if(field==='text'){
        setTimeout(() => {
          this.textmsginput.setFocus();
        }, 250);
      }
    }
  }
  async ViewReport(){
    const loading = await this.loadingController.create({
      message: 'Fetching Report',
      duration: 15000
    });
    await loading.present();
    this.storage.get('channel').then ( (val)=>{
      this.channelcode = val;
      let temp = '$$OpusPair$$' + 'channel=' + '$$OpusVar$$' + this.channelcode;
      //temp += '$$OpusPair$$' + this.medium + '=' + '$$OpusVar$$' + this.mediumaddress;
      temp+='$$OpusPair$$' + 'name=' + '$$OpusVar$$' + this.xd24.cName;
      //temp += this.px62;
      temp += this.queryfortransfer;
      //console.log("in qconly-product:"+this.px62);
      //console.log(ul + temp);
      //console.log(this.dX684.getViewReport()+this.dX684.Encrypt(temp));
      if (this.px62) {
        this.http.get(this.dX684.getViewReport() + this.dX684.Encrypt(temp)).subscribe(data => {
          //console.log(this.dX684.DecryptReturnVal(data)[0]);
          if(this.dX684.DecryptReturnVal(data)) {
            loading.dismiss();
            this.dX684.openURL(this.dX684.DecryptReturnVal(data)[0]);
            loading.onDidDismiss().then(e => {
              this.dX684.presentToastWithOptions('','Report Loaded');
            });
          }
          else{
              loading.dismiss();
              loading.onDidDismiss().then(e => {
                this.dX684.presentToastWithOptions('','Report could not be fetched at this time, please try later!');
            });
          }
        });
      }
    });
  }
  async em(medium) {
    const loading = await this.loadingController.create({
      message: 'Sending ' + medium,
      duration: 15000
    });
    await loading.present();
    this.medium=medium;
    if(this.medium==='Text'){
      this.mediumaddress = String(this.textmsg);
      this.mediumaddress=this.mediumaddress.replace('+','');
      this.mediumaddress=this.mediumaddress.replace('(','');
      this.mediumaddress=this.mediumaddress.replace(')','');
      this.mediumaddress = Number(this.mediumaddress);
    }
    if(this.medium==='Email'){
      this.mediumaddress = this.email;
      //console.log("email:"+this.mediumaddress);
    }
    const ul = this.dX684.getUlEmEncrypted();
    if(this.mediumaddress)
    {
      let re=/\, /gi;
      this.mediumaddress=this.mediumaddress.replace(re,' ');
      re = /\ /gi;
      this.mediumaddress=this.mediumaddress.replace(re,',');
    }
    if(!this.dX684.isEmail(this.mediumaddress, medium)){
      this.dX684.presentToastWithOptions('',medium+' not sent! Please check the format of your '+medium);
      loading.dismiss();
      if(medium==='Email')
        this.emailinput.setFocus();
      if(medium==='Text')
        this.textmsginput.setFocus();
    }
    else
    {
      this.storage.get('channel').then ( (val)=>{
        this.channelcode = val;
        let temp = '$$OpusPair$$' + 'channel=' + '$$OpusVar$$' + this.channelcode;
        temp += '$$OpusPair$$' + this.medium + '=' + '$$OpusVar$$' + this.mediumaddress;
        temp+='$$OpusPair$$' + 'name=' + '$$OpusVar$$' + this.xd24.cName;
        //temp += this.px62;
        temp += this.queryfortransfer;
        //console.log("in qconly-product:"+this.px62);
        //console.log(ul + temp);
        //console.log(ul+this.dX684.Encrypt(temp));
        if (this.px62) {
          if (this.mediumaddress) {
            this.http.get(ul + this.dX684.Encrypt(temp)).subscribe(data => {
			        //console.log(this.dX684.DecryptReturnVal(data));
              if (this.dX684.DecryptReturnVal(data) === "True") {
                    loading.dismiss();
                    loading.onDidDismiss().then(e => {
                    this.analyticsService.logEvent(AnalyticsEventNames.sendEmail,  {page_name: "Available Quotes",product_name:this.dX684.getproductdesc(this.prevSelPro.name, this.dL1)});
                    this.dX684.presentToastWithOptions('',medium+' sent!');
                });
              }
              else{
                  loading.dismiss();
                    loading.onDidDismiss().then(e => {
                    this.dX684.presentToastWithOptions('',medium+' not sent!');
                });
              }
            });
          }
          else {
            this.dX684.presentToastWithOptions('',medium+' not sent! Please check your inputs');
          }
        }
      });
    }
  }
  openGCVRPUCIDIPopover(value) {
    if(value === "GCVRPU") this.analyticsService.logEvent(AnalyticsEventNames.gcvRUP, {product: this.prevSelPro.name, page_name: "Available Quotes"});
    if(value === "CIDIV") this.analyticsService.logEvent(AnalyticsEventNames.costIndices, {product: this.prevSelPro.name, page_name: "Available Quotes"});
    this.gcvrpuchk=false;
    const dXT62 = {
      nm: this.prevSelPro.name,
      value:value,
      adrind:this.adrind,
      source:this.source,
      px:this.px62
    };
    this.dX684.setOpenGCVRPUCIDIPopover(dXT62);
    this.navCtrl.navigateRoot('gcvrup-cidi-popover');
  }
 
  ModalPaymentMode() {
    this.navCtrl.navigateRoot('payment-mode');
  }
  handleEnter(e, medium) {
    //this.renderer.invokeElementMethod(e.target, 'blur');
    e.target.blur();
    this.em(medium);
  }
  kroCheck() {
    this.langOpt = this.dX684.getLan();
    if (this.langOpt === 'eng') {
      this.dLan = this.en.getQD();
      this.dL1 = this.en.getCinf();

    } else if (this.langOpt === 'frn') {
      this.dLan = this.fr.getQD();
      this.dL1 = this.fr.getCinf();
    }
    //console.log("this.dLan.hd:"+this.dLan.hd);
  }
  prodSelect(){
    this.navCtrl.navigateForward('quote-compare-only-product');
  }
}